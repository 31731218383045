<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Dedicated Course Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Select Courses"
              label-for="blog-edit-course"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.course"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getDuration(addCourseForm.course)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Related Courses"
              label-for="blog-edit-course"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="">
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.related_courses"
                  label="name"
                  multiple
                  :options="RelatedCourse"
                  :reduce="(val) => val.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-Description"
              class="mb-2">
            
              <validation-provider #default="{ errors }" name="Description">
               
                 <QuillEditor
                  :editorRef="'contact_descriptionEditor'"
               v-model="addCourseForm.description" 
                />
                
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Objectives"
              label-for="blog-content"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Objectives">
          
            <QuillEditor
                  :editorRef="'objectivesEditor'"
                v-model="addCourseForm.objectives" 
                />
           
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Who should attend?"
              label-for="blog-content"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Objectives">
             <QuillEditor
                  :editorRef="'attendersEditor'"
              v-model="addCourseForm.attenders"
                />
                        <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Select Day"
              label-for="blog-edit-course"
              class="mb-2">
              <validation-provider #default="{ errors }" name="day" rules="">
                <v-select
                  id="blog-s-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="addCourseForm.day"
                  @input="getDayContent(addCourseForm.day)"
                  :options="durationList" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Day Content"
              label-for="blog-content"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Objectives">
                 <QuillEditor
                  :editorRef="'dayContentFormEditor'"
              v-model="dayContentForm"
                />
             
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive,watch } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
import Editor from "@tinymce/tinymce-vue";

import QuillEditor from '@core/components/editor/Editor'
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const RelatedCourse = ref([]);
    const Tabimage = ref("");
    const coursesList = ref([]);
    const durationList = ref([]);

    store.dispatch("othercourses/Allothercourses").then((response) => {
      console.log("Allothercourses", response.data);
      coursesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      day: 1,
      course: "",
      description: "",
      objectives: "",
      attenders: "",
      days_content: "",
      related_courses: [],
    });
    const dayContent = ref([]);
    const getDuration = (item) => {
      durationList.value = [];
      addCourseForm.day = 1;
      coursesList.value.forEach((el) => {
        if (el.id == item.id) {
          for (let i = 1; i <= el.duration; i++) {
            durationList.value.push(i);
          }
        }
      });
      console.log("item.days_content", item);
      addCourseForm.description = item.description;
      console.log("item.description", item.description);
      addCourseForm.related_courses = JSON.parse(item.related_courses);
      addCourseForm.attenders = item.attenders;
      addCourseForm.objectives = item.objectives;
      dayContent.value = JSON.parse(item.days_content);
      dayContentForm.value=dayContent.value[0]
  
      // addCourseForm.days_content = dayContent.value[0];
     
      daycontentArray.value=JSON.parse(item.days_content)
     
    };
   
    store.dispatch("courses/getAllCoursesList").then((response) => {
      console.log("coursesList", response.data);
      response.data.forEach(el=>{
        if(el.online==1){
          RelatedCourse.value.push({
            id:el.id,
            name:`${el.name} Online`,
            link_id:el.link_id
          })
        }
        else{
          RelatedCourse.value.push({
            id:el.id,
            name:el.name,
            link_id:el.link_id
          })
        }
      })
      console.log("RelatedCourse", RelatedCourse.value);
    });
    const daycontentArray=ref([])
    const dayContentForm=ref('')
    watch(dayContentForm , (val, oldVal) => {
      console.log(val)
      console.log(dayContentForm.value)
      if(!dayContentForm.value){
        dayContentForm.value=''
      }
      console.log(  daycontentArray.value[addCourseForm.day-1])
      if(dayContentForm.value !==''){
        console.log("joj")
      daycontentArray.value[addCourseForm.day-1]=dayContentForm.value
      }
      else if( daycontentArray.value[addCourseForm.day-1]){
        dayContentForm.value= daycontentArray.value[addCourseForm.day-1]
        // daycontentArray.value[addCourseForm.day-1]= daycontentArray.value[addCourseForm.day-1]
      }
      console.log(daycontentArray.value)
    });
    const getDayContent = (num) => {
      if(dayContent.value){
    if(dayContent.value[num-1]!==null){
    console.log('daycontentArray.value')
      dayContentForm.value=daycontentArray.value[num-1]
    }
    else{
      dayContentForm.value=''
    }}
    else{
      dayContentForm.value='' 
    }
    };

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      console.log(addCourseForm);
      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("other_course_id", addCourseForm.course.id);
          formData.append("description", addCourseForm.description);
          formData.append("objectives", addCourseForm.objectives);
          formData.append("attenders", addCourseForm.attenders);
        
          formData.append(
            "related_courses",
            JSON.stringify(addCourseForm.related_courses)
          );
          formData.append(" days_content", JSON.stringify(daycontentArray.value));
          store
            .dispatch("othercourses/addDeticatedContent", formData)
            .then((response) => {
              Vue.swal({
                title: "Content Updated ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
    function example_image_upload_handler (blobInfo, success, failure, progress) {
  var xhr, formData;
let token=localStorage.getItem('token')
console.log('token',token)
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce');

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.onload = function() {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};
    return {
      example_image_upload_handler,
      daycontentArray,
      dayContentForm,
      refInputEl,
      refPreviewEl,
      RelatedCourse,
      refInputE2,
      refPreviewE2,
      dayContent,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      getDayContent,
      getDuration,
      durationList,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    QuillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormDatepicker,
    Editor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
